<template>
  <div class="collegeSetting">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="title">学堂信息</span>
      </div>
      <div>
        <el-form ref="form" :model="form" label-width="150px" :rules="rules">
          <el-form-item label="学堂名称：" prop="organizationName">
            <el-input
              v-model="form.organizationName"
              placeholder="请输入学堂名称"
              class="input-width"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="学堂介绍：" prop="introduction">
            <el-input
              v-model="form.introduction"
              class="input-textarea"
              placeholder="请输入请输入6~251个字介绍"
              type="textarea"
              :rows="10"
              maxlength="251"
            ></el-input>
          </el-form-item>
          <el-form-item ref="logo" label="上传学堂logo" prop="logo">
            <upload-image
              v-model="form.logo"
              :upload-img="$refs.logo"
              :width-size="widthSize"
              :height-size="heightSize"
            ></upload-image>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="medium" class="submit" @click="handleSubmit('form')"
              >确定</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-card>

    <el-card class="box-card">
      <div slot="header" class="clearfix header">
        <span class="title">主体信息</span>
        <div v-if="form.verifyStatus == 0" class="headerExplain">
          <div class="explainImg">
            <img src="@/assets/college/College/notCertified.png" alt="" />
          </div>
          <div class="headerExplain_text0">未认证</div>
        </div>
        <div v-if="form.verifyStatus == 1" class="headerExplain">
          <div class="explainImg">
            <img src="@/assets/college/College/examine.png" alt="" />
          </div>
          <div class="headerExplain_text1">审核中</div>
        </div>
        <div v-if="form.verifyStatus == 2" class="headerExplain">
          <div class="explainImg">
            <img src="@/assets/college/College/certified.png" alt="" />
          </div>
          <div class="headerExplain_text2">审核通过</div>
        </div>
        <div v-if="form.verifyStatus == 3" class="headerExplain">
          <div class="explainImg">
            <img src="@/assets/college/College/fail.png" alt="" />
          </div>
          <div class="headerExplain_text3">审核未通过</div>
        </div>
      </div>
      <div v-if="form.verifyStatus == 0">
        <el-button type="primary" size="medium" class="submit" @click="newAuthentication"
          >进行认证</el-button
        >
      </div>
      <div v-else>
        <div v-if="form.verifyStatus == 1" class="explain1">
          <div class="explainL">
            <div class="explainImg">
              <img src="@/assets/college/College/examine.png" alt="" />
            </div>
            <div class="text">学堂信息正在审核中，预计用时2-3个自然日</div>
          </div>
        </div>
        <div v-if="form.verifyStatus == 3" class="explain">
          <div class="explainL">
            <div class="explainImg">
              <img src="@/assets/college/College/fail.png" alt="" />
            </div>
            <div class="text">审核未通过,原因:{{ form.verifyFailReason }}</div>
          </div>
          <div class="explainR" @click="newAuthentication">重新认证</div>
        </div>
        <el-row :gutter="20">
          <el-col v-if="form.tenantCategory == 0" :span="12">
            <div>
              <el-form ref="formTenantL" :model="formTenantL" label-width="150px">
                <el-form-item label="企业/机构名称：">
                  <div>{{ form.tenantName }}</div>
                </el-form-item>
                <el-form-item label="统一社会信用代码：">
                  <div>{{ form.credentials }}</div>
                </el-form-item>
                <el-form-item label="企业/机构所属行业：">
                  <div>{{ filterIndustry(form.belongIndustry) }}</div>
                </el-form-item>
                <el-form-item label="营业执照：">
                  <div v-if="form.businessLicense !== ''" class="businessLicense">
                    <img :src="form.businessLicense" alt="" />
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </el-col>
          <el-col v-if="form.tenantCategory == 1" :span="12">
            <div>
              <el-form ref="formTenantR" :model="formTenantR" label-width="150px">
                <el-form-item label="真实姓名：">
                  <div>{{ form.tenantContactName }}</div>
                </el-form-item>
                <el-form-item label="身份证号码：">
                  <div>{{ form.credentials }}</div>
                </el-form-item>
                <el-form-item label="常驻地区：">
                  <div>{{ form.address }}</div>
                </el-form-item>
                <el-form-item label="身份证：">
                  <div class="cardBox">
                    <div v-if="form.idCardFrontImage" class="cardIdL">
                      <img :src="form.idCardFrontImage" alt="" />
                    </div>
                    <div v-if="form.idCardBackImage" class="cardIdR">
                      <img :src="form.idCardBackImage" alt="" />
                    </div>
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </el-col>
          <el-col v-if="form.tenantCategory == 2" :span="12">
            <div>
              <el-form ref="formTenantR" :model="formTenantR" label-width="150px">
                <el-form-item label="院校名称：">
                  <div>{{ form.tenantName }}</div>
                </el-form-item>
                <el-form-item label="专业方向	：">
                  <div>{{ form.professionalDirection }}</div>
                </el-form-item>
                <el-form-item label="所在地区：">
                  <div>{{ form.address }}</div>
                </el-form-item>
                <el-form-item label="证明文件：">
                  <div class="cardBox">
                    <div v-if="form.collegeCertificate" class="cardIdL">
                      <img :src="form.collegeCertificate" alt="" />
                    </div>
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-card>
  </div>
</template>

<script>
const industry = [
  { key: 0, value: '未知' },
  { key: 1, value: '农、林、牧、渔业' },
  { key: 2, value: '采矿业' },
  { key: 3, value: '制造业' },
  { key: 4, value: '电力、燃气及水的生产和供应业' },
  { key: 5, value: '建筑业' },
  { key: 6, value: '交通运输' },
  { key: 7, value: '仓储和邮政业' },
  { key: 8, value: '批发和零售业' },
  { key: 9, value: '住宿和餐饮业' },
  { key: 10, value: '金融业' },
  { key: 11, value: '房地产业' },
  { key: 12, value: '租赁和商务服务业' },
  { key: 13, value: '科学研究、技术服务和地质勘查业' },
  { key: 14, value: '水利、环境和公共设施管理业' },
  { key: 15, value: '居民服务和其他服务业' },
  { key: 16, value: '教育 ' },
  { key: 17, value: '卫生、社会保障和社会福利业' },
  { key: 18, value: '文化、体育和娱乐业' },
]
import { createNamespacedHelpers } from 'vuex'
const { mapMutations } = createNamespacedHelpers('user')
import { getSearchCollegeNews, getEditCollegeNews } from '../../api/college'
import to from 'await-to'
import UploadImage from '../../components/UploadImage.vue'
export default {
  name: 'CollegeSetting',
  components: {
    UploadImage,
  },
  data() {
    var validateImg = (rule, value, callback) => {
      if (value == '') {
        callback(new Error('请上传图片'))
      } else {
        callback()
      }
    }
    return {
      widthSize: 100,
      heightSize: 100,
      form: {
        organizationId: '',
        introduction: '',
        logo: '',
        organizationName: '',
        belongIndustry: '',
        credentials: '',
        credentialsCategory: '',
        businessLicense: '',
        tenantId: '',
        tenantIntroduction: '',
        tenantName: '',
        contactName: '',
        idCardFrontImage: '',
        idCardBackImage: '',
        address: '',
        operatorName: '',
        operatorId: '',
      },
      rules: {
        organizationName: [
          { required: true, message: '请输入学堂名称', trigger: 'blur' },
          { min: 4, max: 12, message: '请输入4-12字学堂名称', trigger: 'blur' },
        ],
        introduction: [{ min: 6, max: 251, message: '请输入请输入6~251个字介绍', trigger: 'blur' }],
        logo: [{ required: true, validator: validateImg, trigger: 'change' }],
      },
      formTenantL: {},
      formTenantR: {},
    }
  },
  created() {
    this.getSearchCollegeNewsData()
  },
  methods: {
    ...mapMutations({
      collegeInfos: 'SET_COLLEGE_INFO',
    }),
    async getSearchCollegeNewsData() {
      const [res, err] = await to(getSearchCollegeNews())
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.form = res.data
      this.form.organizationId = res.data.id
    },
    async handleSubmit(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          const [, err] = await to(getEditCollegeNews(this.form))
          if (err) return this.$message({ type: 'error', message: err.msg })
          this.$message({ type: 'success', message: '修改成功' })
          this.collegeInfos(this.form)
        }
      })
    },
    filterIndustry(val) {
      let str = ''
      industry.map(v => {
        if (val == v.key) {
          str = v.value
        }
      })
      return str
    },
    newAuthentication() {
      this.$router.push({
        path: '/college/CollegeRealName',
        query: { organizationId: this.form.id },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.box-card {
  margin-top: 30px;
  margin-bottom: 20px !important;
  img {
    width: 120px;
    height: 150px;
  }
  .header {
    display: flex;
    align-items: center;
    .title {
      font-size: 18px;
      float: left;
    }
    .headerExplain {
      display: flex;
      align-items: center;
      float: left;
      margin-left: 15px;
      font-size: 14px;
      .headerExplain_text0,
      .headerExplain_text1 {
        color: #b1b1b1;
        margin-left: 5px;
      }
      .headerExplain_text2 {
        color: #fdab34;
        margin-left: 5px;
      }
      .headerExplain_text3 {
        color: #ff4d4f;
        margin-left: 5px;
      }
    }
  }

  .explain {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 60%;
    height: 40px;
    background-color: #fff1f0;
    border: 1px solid #ffa39e;
    padding-left: 15px;
    border-radius: 2px;
  }
  .explain1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 60%;
    height: 40px;
    background-color: #fffbe6;
    border: 1px solid #ffe58f;
    padding-left: 15px;
    border-radius: 2px;
  }
  .explainL {
    display: flex;
    align-items: center;
    .text {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
      margin-left: 8px;
    }
  }
  .explainR {
    margin-right: 30px;
    color: #ff7b33;
    font-size: 14px;
    cursor: pointer;
  }
  .explainImg {
    width: 16px;
    height: 16px;
  }
  .input-width {
    width: 290px;
  }
  .input-textarea {
    width: 609px;
  }
  .cardBox {
    display: flex;
    .cardIdR {
      margin-left: 30px;
    }
    img {
      width: 150px;
      height: 100px;
    }
  }
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
